<template>

    <div v-if="view === 'mobile'">

        <router-link class="box flex flex-col h-24 w-11/12 shadow-md" :to="{name: 'ProjectsDetail', params: { id: data.id }}" >

            <div class="flex flex-col mb-2">

                <div class="h-auto flex flex-row justify-between items-center">
                    <div class="text-xxs font-semibold">{{ data.project_name }}</div>
                    <div class="text-font-dark text-xxs ml-4">#{{ data.project_link }}</div>
                </div>

                <div class="h-auto flex flex-row justify-between items-center mt-1">

                    <span class="text-3xs text-font-gray text-left">
                        Client: {{ data.client_name }}
                    </span>

                    <span class="text-3xs text-xs ml-4 font-bold uppercase">{{ data.status }}</span>

                </div>

            </div>

            <div class="flex-1 min-h-0 flex flex-row mt-auto">

                <template v-for="(col, k) in projectFields">

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="data[k] || data[k] === null || data[k] === 0" :key="k">
                        <div class="text-3xs text-font-gray">{{ col.name }}</div>
                        <div class="text-xs font-semibold">
                            <template v-if="data[k] || data[k] === 0">
                                {{ data[k] | numberFormat(col.dec) }}<template v-if="col.sufix">{{ col.sufix }}</template>
                            </template>
                            <template v-else>N/A</template>
                        </div>
                    </div>

                </template>

            </div>

        </router-link>

    </div>

    <div v-else>
        <router-link class="box flex flex-col h-32" :to="{name: 'ProjectsBillingChild', params: {projectId: data.id }}" >

            <div class="h-8 flex flex-row justify-between items-center">

                <div class="h-full w-2/3 flex flex-row justify-start items-center">

                    <span class="text-white font-semibold truncate text-sm">{{ data.project_name }}</span>

                </div>

                <div class="h-full w-1/3 flex flex-row justify-end items-center">

                    <span class="text-font-gray text-xs font-semibold">#{{ data.project_link }}</span>

                </div>


            </div>

            <div class="h-8 flex flex-row justify-between items-center">

                <div class="h-full w-2/3 flex flex-row justify-start items-center break-words">
                    <span class="text-font-gray text-xs truncate">Client: {{ data.client_name }}</span>
                </div>

                <div class="h-full w-1/3 flex flex-row justify-end items-center">
                    <span class="text-white capitalize font-semibold text-xs mr-3">{{ data.status }}</span>
                </div>

            </div>

            <div class="flex-1 min-h-0 flex flex-row">

                <div class="h-full w-full" v-for="(col, k) in projectFields" :key="k">

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="data[k] || data[k] === null || data[k] === 0">
                        <span class="text-xs text-font-gray mb-1">{{ col.name }}</span>
                        <span class="text-xl font-semibold">
                            <span v-if="data[k] || data[k] === 0">
                                {{ data[k] | numberFormat(col.dec) }}
                                <span v-if="col.sufix">{{ col.sufix }}</span>
                            </span>
                            <span v-else>N/A</span>
                        </span>
                    </div>

                </div>

            </div>

        </router-link>

    </div>

</template>

<script>
export default {
    props: ['data', 'view'],
    data() {
        return {
            projectFields: {
                "billing": { name: 'Billing', dec: 0 } ,
                "average_rate": { name: 'Average Rate', dec: 1, sufix:' €/h' } ,
                "hours": { name: 'Hours', dec: 0, sufix: 'h' } ,
                "margin_current": { name: 'Margin', dec: 1, sufix: '%' } ,
            }
        }
    }
}
</script>